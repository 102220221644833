html {
	background: #2b2b40;
}

html, body {
	margin: 0;
	padding: 0;
}

body {
	font-family: "San Francisco", "Helvetica Neue", "Helvetica", "Arial";
}

a {
	color: #00a4ca;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

img {
	width: 100%;
}

h1 strong, h2 strong {
	font-weight: 700;
}

h1 {
	font-weight: 270;
	font-size: 2.3em;
	margin: 0;
}

h2 {
	font-weight: 300;
	font-size: 2.2em;
	margin: 0 0 30px 0;
}

h3 {
	margin: 20px 0 10px 0;
}


p, address {
	font-size: 1.38em;
	color: #080808;
	margin-bottom: 20px;
	font-weight: 300;
	line-height: 1.4em;
}
