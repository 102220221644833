


section.pricing {
  //background: #f5f5f5;
  //background: #4182e4;
  //background: linear-gradient(to bottom, #4182e4, #33AEFF);
}

.pricing .card {
  border: none;
  border-radius: 1rem;
  transition: all 0.2s;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.pricing hr {
  margin: 1.5rem 0;
}

.pricing .card-title {
  margin: 0.5rem 0;
  font-size: 0.9rem;
  letter-spacing: .1rem;
  font-weight: bold;
}

.pricing .card-price {
  font-size: 3rem;
  margin: 0;
}

.pricing .card-price .period {
  font-size: 0.8rem;
}

.pricing ul li {
  margin-bottom: 1rem;
}

.pricing .text-muted {
  opacity: 0.7;
}

.pricing .btn {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: .1rem;
  font-weight: bold;
  padding: 1rem;
  opacity: 0.7;
  transition: all 0.2s;
}

/* Hover Effects on Card */

@media (min-width: 992px) {
  .pricing .card:hover {
    margin-top: -.25rem;
    margin-bottom: .25rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
  }
  .pricing .card:hover .btn {
    opacity: 1;
  }
}



.plans {
	@extend %flexbox;
	@include flex-flow(wrap);
	padding: 50px 0 30px 0;




	.plan {
		list-style: none;
		padding: 0;
		margin: 0 10px 50px 10px;
		text-align: center;
		border: 1px solid #eee;
		border-radius: 5px;
		box-shadow: 0px 0px 10px #eee;
		width: 100%;

		.highlighted {
			font-size: 1.2em
		}

		.pricing-cta {
			padding: 0;

			a {
				display: block;
				box-sizing: border-box;
				padding: 20px 0;
				border-radius: 0 0 2px 2px;
				border: 0;
			}
		}

		@media #{$desktop} {
			-webkit-flex: 1;
			flex: 1;
		}

		li {
			border-top-right-radius: 5px;
			border-top-left-radius: 5px;
			padding: 20px 0;
			h3 {
				padding: 0;
				margin: 0;
				color: #fff;
				font-weight: normal;
			}
		}
	}



}

.faq {
	@media #{$desktop} {
		@include columns(2);
	}
	color: #666;
	div {
		break-inside: avoid;
		padding: 25px 0;
	}

	dt {
		font-weight: bold;
		margin: 0 0 5px 0;
	}

	dd {
		padding: 0;
		margin: 0;

	}
}
