/* lato-300 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/lato-v17-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/lato-v17-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lato-v17-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato-v17-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lato-v17-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lato-v17-latin-300.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/lato-v17-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/lato-v17-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lato-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato-v17-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lato-v17-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lato-v17-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/lato-v17-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/lato-v17-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lato-v17-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato-v17-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lato-v17-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lato-v17-latin-700.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-900 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/lato-v17-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/lato-v17-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lato-v17-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato-v17-latin-900.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lato-v17-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lato-v17-latin-900.svg#Lato') format('svg'); /* Legacy iOS */
}


p {
    color: #506177;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-weight: 400;
}
h2 {
    color: #274060;
    font-family: 'Lato', sans-serif;
    //font-size: 20px;
    font-weight: 600 !important;
}
h1 {
    color: #274060;
    font-family: 'Lato', sans-serif;
    //font-size: 20px;
    font-weight: 600 !important;
}
.container, .text-container {
	margin: 0 auto;
	position: relative;
	padding: 0 20px;
}

.text-container {
	max-width: 750px;
}

.container {
	max-width: 1140px;

	&.max-container {
		max-width: 100%;
		padding: 0;
	}
}

.element {
    background-position: center bottom;
    background-size: 1440px 126px;
    background-repeat: repeat-x;
    	// background: linear-gradient(to bottom, $middle-gradient-color 0%, $secondary-brand-color 100%) no-repeat #a05fb7;
    		  background: linear-gradient(to bottom, #33AEFF, #a0d3fb);

    	margin-bottom: -5px;
        position: relative;
}

header {
	color: #fff;
	padding: 20px 0;
	background: #fff;
	//background: $brand-color; /* Old browsers */
	//background: linear-gradient(to bottom, $brand-color 0%, $middle-gradient-color 100%) no-repeat $brand-color;

	a {
		color: #fff;
		text-decoration: none;
		z-index: 1;
		position: relative;

		&:hover {
			text-decoration: none;
		}
	}

	.company-name {
		font-size: 1.7em;
		line-height: 0;

		a {
			display: inline-block;
		}

		img {
			display: block;
			width: auto;
		}
	}
}

.content {
	background: #fff;
	padding: 1px 0 0 0;
	position: relative;
}

.shadow{
	max-width: 100%;
	height: auto;
	display: block;
	box-shadow: 0 1px 0 #ccc, 0 1px 0 1px #eee;
	border-radius: 2px;
	margin-left: auto;
	margin-right: auto;
	position: relative;}

.screenshot{
	max-width: 100%;
	height: auto;
	display: block;
	box-shadow: 0 1px 0 #ccc, 0 1px 0 1px #eee;
	border-radius: 2px;
	margin-left: auto;
	margin-right: auto;
	background: #DDD url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2244%22%20height%3D%2212%22%20viewBox%3D%220%200%2044%2012%22%3E%3Ccircle%20cx%3D%226%22%20cy%3D%226%22%20r%3D%224%22%20fill%3D%22%23eee%22%20%2F%3E%3Ccircle%20cx%3D%2222%22%20cy%3D%226%22%20r%3D%224%22%20fill%3D%22%23eee%22%20%2F%3E%3Ccircle%20cx%3D%2238%22%20cy%3D%226%22%20r%3D%224%22%20fill%3D%22%23eee%22%20%2F%3E%3C%2Fsvg%3E') 4px 4px no-repeat;
	padding: 20px 0 0 0;
	position: relative;
}

section {
	padding: 20px 0;
}

section + section {
	padding-top: 0;
}

.subtext {
	margin-top: 10px;
	text-align: center;
}


.cta {
	margin: 60px 0;
}

.page h2 {
	text-align: center;
}

blockquote {
	padding: 18px 25px;
	margin: 0;
	quotes: "\201C""\201D""\2018""\2019";
	font-style: italic;

	.author {
		display: block;
		font-weight: bold;
		margin: 10px 0 0 0;
		font-size: .85em;
		font-style: normal;
	}

	p {
		display: inline;
	}
}

blockquote:before {
	color: #ccc;
	content: open-quote;
	font-size: 4em;
	line-height: 0.1em;
	margin-right: 0.25em;
	vertical-align: -0.4em;
}

.square-image {
	width: 150px;
	height: 150px;
	overflow: hidden;
	margin: 25px auto 0 auto;
	position: relative;
	border-radius: 200px;

	img {
		position: absolute;
		left: -1000%;
		right: -1000%;
		top: -1000%;
		bottom: -1000%;
		margin: auto;
		width: 300px;

	}
}

.page {
	margin-bottom: 0;
	padding-bottom: 80px;
}

.center-text {
	text-align: center;
}

.editor-link {
 	display: none;
	margin-top: 0;
	.btn {
		border: 0;
		border-radius: 2px;
		width: 100%;
		max-width: 500px;
		box-sizing: border-box;
		font-size: 2rem;
		text-decoration: none;
		padding: 10px 15px;
		margin: 0;
		font-size: 18px;
		cursor: pointer;
		background-color: #f7e064;
		color: #333;
		box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.2);

		&:hover {
			background-color: #f4d525;
			color: #333;
		}
	}

}
